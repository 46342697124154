import React, { useRef } from 'react';
import { BACKGROUND_IMG_URL } from '../../lib/constants';
import { getKvThumbnailUrl } from '../../lib/api/utils';
import { EventPublicDetailsResponseType, PresenterParticipantDetailsResponseType, PublicMeetingDetailsResponseType } from '../../lib/api';
import Button from '../../components/button';
import Icon from '../../components/_base/Icon';
import DateRange, { TimeRange } from './DateRange';
import useConnectToPublicMeetingMethod from '../../hooks/use-connect-to-public-meeting-method';
import SelectDateInput from './SelectDateInput';
import './DetailsWrapper.scss';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import {
  getFullDateInLocalFormat,
  getCompareAsc,
  getStartCurrentISODay,
  getIsToday,
  getCurrentTimezone,
  dateToFormattedString,
  DateFnsFormats,
  convertStringToDate,
  getEventOffsetGMT,
  TimeZoneDisplayTypeLabels
} from '../../lib/helpers/dateHelper';
import useChangeBackgroundSize from '../../hooks/use-change-background-size';
import { AppConfigurator } from '../../lib/services/app-configuration/AppConfigurator';
import { ConnectionDetailsType } from '../../lib/api/connection.types';
import { JoinMethod } from '../../lib/join-method/join-method';
import { useTemplateProcessor } from '../../hooks/use-template-processor';


export enum PageType {
  PRESENTER = 'PRESENTER',
  PARTICIPANT = 'PARTICIPANT',
}

export type PropsType = {
  logoUrl?: string,
  details: {
    endTime: string,
    startTime: string,
    name: string,
    timeZone: string,
    description?: string;
  } & Partial<EventPublicDetailsResponseType>
}

export interface IProps {
  type: PageType;
  meetingDetails: PresenterParticipantDetailsResponseType;
  timeZone: string;
  eventName?: string;
  errorMessage?: string;
}

export interface IGroupedMeetingsList {
  [date: string]: ConnectionDetailsType[]
}

export interface IConnectionProps {
  length: number;
  timeZone: string;
  isPresenterPage: boolean;
  connectionDetails: ConnectionDetailsType
  formattedFullSelectedDate?: string;
}

export interface IButtonProps {
  connectionDetails: ConnectionDetailsType
}

export interface IConnectionsProps {
  type: PageType;
  errorMessage?: string;
  timeZone: string;
  meetingDetails: PresenterParticipantDetailsResponseType;
  formattedFullSelectedDate?: string;
}

export interface IPresenterConnections {
  timeZone: string;
  selectedDate: string;
  isPresenterPage: boolean;
  organizationName: string;
  formattedFullSelectedDate: string;
  setMeetingsDate: (date: string) => void;
  connectionDetails: ConnectionDetailsType[];
  meetings: { [date: string]: ConnectionDetailsType[] };
}
export interface IPresenterHeaderProps {
  formattedFullSelectedDate: string;
  timeZone: string;
  isShowConnectButton: boolean;
  selectedDate: string;
  setMeetingsDate: (date: string) => void;
  organizationName: string;
  connectionDetails?: ConnectionDetailsType | null;
  dates: string[];
}

export interface IParticipantConnections {
  timeZone: string;
  errorMessage: boolean;
  organizationName: string;
  isPresenterPage: boolean;
  containsUpcomingOnly?: boolean;
  formattedFullSelectedDate: string;
  connectionDetails: ConnectionDetailsType[];
}

export interface IParticipantHeaderProps {
  isErrorsExist: boolean;
  organizationName: string;
  containsUpcomingOnly?: boolean;
  isShowConnectButton: boolean;
  formattedFullSelectedDate: string;
  isMoreThanOneConnectionExist: boolean;
  connectionDetails: ConnectionDetailsType | null;
}

const ONE=1;

export const ButtonBox = (props: IButtonProps): JSX.Element | null => {
  const { connectionDetails: { connection }} = props;
  const joinMethod = JoinMethod.create(connection);

  const isConnectionExist = !!connection && (connection?.embedCodeId || connection?.connectionLink);
  const connectToMeeting = useConnectToPublicMeetingMethod( { } as PublicMeetingDetailsResponseType, joinMethod as JoinMethod);

  if (!isConnectionExist) return null;
  return (
    <div className='button-box text-left py-4'>
      {
        connection.embedCodeId &&
          <Button.Thumbnail
            className={'bg-transparent'}
            name='join'
            onClick={connectToMeeting}
          >
            <img
              className='h-full w-full object-contain'
              alt='knovio thumbnail'
              src={getKvThumbnailUrl(connection.embedCodeId)}
            />
          </Button.Thumbnail>
      }
      {
        connection.connectionLink &&
          <Button.Common
            name='join'
            disabled={!connection}
            onClick={connectToMeeting}
            label='Connect to Meeting'
          >
            <Icon.RightArrow className='fill-current text-white mb-1 ml-2' height='15px' width='15px'/>
          </Button.Common>
      }
    </div>
  );
};

export const ConnectionDetails = (props: IConnectionProps): JSX.Element | null => {
  const limit = 1;
  const limitHeight = 220;
  const contentRef = React.useRef<HTMLDivElement>(null);
  const currentRef = React.useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [isShowMoreButtonExist, setShowMoreButtonExist] = React.useState<boolean>(false);
  const { connectionDetails, length, isPresenterPage, timeZone } = props;
  const isMoreThanOneConnection = length > limit;
  const toggle = (state: boolean) => {
    if (contentRef.current && isShowMoreButtonExist) {
      contentRef.current.style.height = state ? 'max-content' : `${limitHeight}px`;
    }
    setExpanded(state);
  };

  React.useEffect(() => {
    if (!contentRef.current) return;
    if (!currentRef.current) return;
    if (currentRef.current.getBoundingClientRect?.().height >= limitHeight) {
      contentRef.current.style.height = `${limitHeight}px`;
      contentRef.current.style.overflowY = 'hidden';
      setShowMoreButtonExist(true);
      return;
    }
  }, [contentRef, connectionDetails]);
  if (!Object.values(connectionDetails || {}).length) return null;
  if (connectionDetails.errorMessage) {
    return (
      <div className={`${isMoreThanOneConnection ? 'm-4' : 'm-0'} font-size-14px error-message bg-cool-gray w-100 p-8`}>
        <div ref={currentRef} className='whitespace-pre-wrap leading-loose overflow-hidden'>
          {connectionDetails.errorMessage}
        </div>
      </div>
    );
  }

  return (<>
    {
      isPresenterPage && isMoreThanOneConnection && <div className='px-8'>
        <TimeRange
          timeZone={timeZone}
          endTime={connectionDetails.roomEndDateTime}
          startTime={connectionDetails.roomStartDateTime}
          formattedDateByEventTimeZone={props.formattedFullSelectedDate}
        />
      </div>
    }
    <div className={`${isMoreThanOneConnection ? 'mt-4 mx-4 p-4' : 'm-0 p-8'} bg-cool-gray w-100`}>
      <div className='content-box font-size-14px' ref={contentRef}>
        { isMoreThanOneConnection && <ButtonBox connectionDetails={connectionDetails} />}
        <div className='font-size-13px border-{#ddd} font-semibold'>
          Connection Details
        </div>
        { connectionDetails?.connection?.meetingLocation
          ? (
            <div
              ref={currentRef}
              dangerouslySetInnerHTML={{ __html: connectionDetails.connection.meetingLocation }}
              className='pt-4 text-sm whitespace-pre-wrap break-words overflow-hidden h-max'
            />)
          : (
            <div ref={currentRef} className='pt-4 whitespace-pre-wrap leading-loose overflow-hidden h-max break-words'>
              { connectionDetails?.connection?.meetingInvitation
                ? connectionDetails?.connection?.meetingInvitation
                : null}
            </div>)
        }
      </div>
      {
        isShowMoreButtonExist && (
          <div
            className='show-more-button w-32 text-primary border mt-4 p-1 rounded-sm font-size-13px font-medium text-center'
            onClick={() => toggle(!expanded)}
          >
            {expanded ? 'Show Less' : 'Show More'}
          </div>
        )
      }
    </div>
  </>
  );
};

export const EventDetails: React.FC<PropsType> = (props: PropsType) => {
  const { logoUrl, details } = props;
  const { endTime, startTime, name, timeZone, logoTitle, description, eventTitleForPassport, timeZoneDisplayType, customTimeZoneDisplay = '' } = details;
  const wrapperCssClass = 'p-8 overflow-hidden flex flex-col justify-start md:justify-between items-start';
  const commonFontCssClass = 'font-semibold text-left';
  const templateProcessor = useTemplateProcessor();
  if (!startTime && !endTime) return null;
  const startDateTime = utcToZonedTime(startTime, timeZone);
  const timeZoneLabel = getEventOffsetGMT(timeZoneDisplayType as TimeZoneDisplayTypeLabels, customTimeZoneDisplay, startDateTime, timeZone);
  const transformedTimeZone = timeZoneDisplayType === TimeZoneDisplayTypeLabels.Custom ? templateProcessor(timeZoneLabel) : timeZoneLabel;

  return (
    <div className='event-details' id='event-details-info' style={{ height: 'max-content' }}>
      {
        logoUrl &&
          <img
            src={logoUrl}
            alt={logoTitle}
            title={logoTitle}
            className='max-w-xs px-8'
            style={{ objectFit: 'contain', height: '8rem' }}
          />
      }
      <div className={wrapperCssClass} >
        <div className='pt-2'>
          <p
            className={`text-primary font-size-31px truncate-advanced-4 ${commonFontCssClass}`}
            style={{ letterSpacing: 'normal', lineHeight: '1.1' }}
          >
            {eventTitleForPassport || name}
          </p>
          <div
            className={`date-range pt-4 text-primary font-size-14px ${commonFontCssClass} uppercase`}
            style={{ letterSpacing: '0.7px', lineHeight: '1.5' }}
          >
            <DateRange startTime={startTime} endTime={endTime} timeZone={timeZone}/>
          </div>
          <p className='text-left font-size-11px' style={{ color: '#333', letterSpacing: '0.55px', lineHeight: '1.91' }}>
            {transformedTimeZone}
          </p>
        </div>
        {
          description &&
            <div
              style={{ width: '100%', wordBreak: 'break-all' }}
              className='description-block pt-4 whitespace-pre-wrap truncate-advanced-10'
              dangerouslySetInnerHTML={{ __html: description }}
            />
        }
      </div>
    </div>
  );
};

export const ParticipantHeader = (props:IParticipantHeaderProps): JSX.Element => {
  const { isErrorsExist, formattedFullSelectedDate, organizationName,
    connectionDetails, isShowConnectButton, containsUpcomingOnly = false, isMoreThanOneConnectionExist } = props;
  return (
    <div className='p-8'>
      {
        containsUpcomingOnly && !isMoreThanOneConnectionExist
          ? (
            <p
              style={{ letterSpacing: 'normal', lineHeight: '1.4' }}
              className='w-100 pb-2 text-primary font-size-15px font-medium w-full italic'
            >
              Upcoming Meeting
            </p>
          )
          : null
      }
      {
        !isErrorsExist
          ? (
            <p
              style={{ letterSpacing: '1.7px', lineHeight: '1.24' }}
              className='mb-8 text-primary font-size-17px font-bold uppercase'
            >
              {formattedFullSelectedDate}
            </p>
          )
          : null
      }
      <div className='flex justify-between items-center'>
        <p className='organization-name font-size-22px font-medium break-words overflow-hidden'>
          {organizationName}
        </p>
      </div>
      { connectionDetails && isShowConnectButton &&
        <ButtonBox connectionDetails={connectionDetails} />
      }
    </div>
  );
};


const isToday = 0,
  isPast = -1,
  isFuture = 1;

const getDateStatus = (status: number): string | null => {
  switch (status) {
  case isPast:
    return 'Past Date';
  case isToday:
    return 'Today';
  case isFuture:
    return 'Upcoming Date';
  default:
    return null;
  }
};

export const PresenterHeader = (props: IPresenterHeaderProps): JSX.Element => {
  const { timeZone, formattedFullSelectedDate, organizationName, selectedDate,
    dates, setMeetingsDate, connectionDetails, isShowConnectButton } = props;
  const limit = 1;

  const currentDate = new Date();
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const selectedDateVal = convertStringToDate(dateToFormattedString(new Date(selectedDate), DateFnsFormats.FullDate, getCurrentTimezone()).slice(0, 10));

  selectedDateVal.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const status = getCompareAsc(selectedDateVal, currentDate);

  const isMoreThanOneDateExist = dates.length > limit;

  return (
    <div>
      <div className='p-8 flex justify-between items-center'>
        <p className='organization-name font-size-22px font-medium break-words overflow-hidden'>
          {organizationName}
        </p>
        {
          isMoreThanOneDateExist
            ? (
              <SelectDateInput
                dateOptions={dates}
                timeZone={getCurrentTimezone()}
                selectedDate={selectedDate}
                onChangeSelection={setMeetingsDate}
                label={'Date'}
                isMeetLink={true}
              />
            )
            : null
        }
      </div>
      <div className='separate-line border-{#ddd} border w-100'/>
      <div className='flex flex-wrap items-center justify-start p-8'>
        {
          isMoreThanOneDateExist
            ? (
              <p
                style={{ letterSpacing: 'normal', lineHeight: '1.4' }}
                className={`w-100 pb-2 text-primary font-size-15px w-full ${Math.abs(status) ? 'italic font-medium' : 'uppercase font-bold'}`}
              >
                {getDateStatus(status)}
              </p>
            )
            : null
        }
        <p
          style={{ letterSpacing: '1.7px', lineHeight: '1.24' }}
          className='formatted-date pr-4 text-primary font-size-17px font-bold uppercase'
        >
          {formattedFullSelectedDate}
        </p>
        {
          isMoreThanOneDateExist
            ? (
              <SelectDateInput
                timeZone={timeZone}
                selectedDate={selectedDate}
                dateOptions={dates}
                onChangeSelection={setMeetingsDate}
                label={'Date'}
                isMeetLink={true}
              />
            )
            : null
        }
        { connectionDetails &&
          <TimeRange
            timeZone={timeZone}
            endTime={connectionDetails.roomEndDateTime}
            startTime={connectionDetails.roomStartDateTime}
            formattedDateByEventTimeZone={formattedFullSelectedDate}
          />
        }
      </div>
      {connectionDetails && isShowConnectButton &&
        <div className='px-8 pb-8'>
          <ButtonBox connectionDetails={connectionDetails} />
        </div>
      }
    </div>
  );
};

export const PresenterConnections = (props: IPresenterConnections):JSX.Element => {
  const { connectionDetails, setMeetingsDate, timeZone,
    formattedFullSelectedDate, organizationName, isPresenterPage, selectedDate, meetings } = props;
  const limit = 1;
  const firsConnection = meetings[selectedDate]?.length === limit ? meetings[selectedDate][0] : null;

  return (
    <>
      <PresenterHeader
        timeZone={timeZone}
        selectedDate={selectedDate}
        connectionDetails={firsConnection}
        dates={Object.keys(meetings)}
        setMeetingsDate={setMeetingsDate}
        organizationName={organizationName}
        formattedFullSelectedDate={formattedFullSelectedDate}
        isShowConnectButton={connectionDetails && meetings[selectedDate].length === limit}
      />
      {
        connectionDetails?.length && meetings[selectedDate].map((connection: ConnectionDetailsType, index: number, list: ConnectionDetailsType[]) =>
          <ConnectionDetails
            timeZone={timeZone}
            length={list.length}
            connectionDetails={connection}
            key={`${index} ${selectedDate}`}
            isPresenterPage={isPresenterPage}
            formattedFullSelectedDate={formattedFullSelectedDate}
          />
        )}
    </>
  );
};

export const ParticipantConnections = (props: IParticipantConnections):JSX.Element => {
  const limit = 1;
  const { organizationName, connectionDetails, formattedFullSelectedDate,
    timeZone, isPresenterPage, errorMessage, containsUpcomingOnly } = props;
  const isShowConnectButton = connectionDetails?.length === limit;
  const isMoreThanOneConnectionExist = connectionDetails?.length > limit;
  const connection = connectionDetails?.length ? connectionDetails[0] : null;
  const isErrorsExist = errorMessage || !!(connectionDetails.length && connectionDetails.some(c => c['errorMessage']));

  return (
    <>
      <ParticipantHeader
        connectionDetails={connection}
        isErrorsExist={isErrorsExist}
        organizationName={organizationName}
        containsUpcomingOnly={containsUpcomingOnly}
        isShowConnectButton={isShowConnectButton}
        formattedFullSelectedDate={formattedFullSelectedDate}
        isMoreThanOneConnectionExist={isMoreThanOneConnectionExist}
      />
      {
        connectionDetails?.length && connectionDetails.map((connection: ConnectionDetailsType, index: number, list: ConnectionDetailsType[]) =>
          <ConnectionDetails
            key={index}
            timeZone={timeZone}
            length={list.length}
            connectionDetails={connection}
            isPresenterPage={isPresenterPage}
            formattedFullSelectedDate={formattedFullSelectedDate}
          />
        )}
    </>
  );
};


export const ConnectionDetailsWrapper = (props: IConnectionsProps): JSX.Element => {
  const limit = 1;
  const { type, timeZone, meetingDetails, errorMessage } = props;
  const { connectionDetails, organizationName, containsUpcomingOnly } = meetingDetails;
  const currentDay = getStartCurrentISODay();
  const getGroupedMeetingsList = (meetings: ConnectionDetailsType[]): IGroupedMeetingsList => {
    const groupedMeetingsList: IGroupedMeetingsList = {};
    (meetings || []).forEach((meeting: ConnectionDetailsType) => {

      if (groupedMeetingsList[meeting.roomStartDateTime]) {
        groupedMeetingsList[meeting.roomStartDateTime].push(meeting);
      } else {
        groupedMeetingsList[meeting.roomStartDateTime] = [meeting];
      }
    });
    return groupedMeetingsList;
  };


  const sortedConnectionDetails = (connectionDetails || [])
    .slice()
    .sort((c1, c2) => getCompareAsc(c1.roomStartDateTime, c2.roomStartDateTime));

  const meetings = getGroupedMeetingsList(sortedConnectionDetails);

  const getDefaultSelectedDate = (): string => {
    if (!connectionDetails) return currentDay;
    const days = Object.keys(meetings).sort(getCompareAsc);
    return days.find(day => getIsToday(day)) || days[0];
  };
  const [selectedDate, setMeetingsDate] = React.useState<string>(getDefaultSelectedDate());
  const isPresenterPage = type === PageType.PRESENTER;

  const sortedStartDays = (meetings[selectedDate] || [])
    .map(connectionDetails => connectionDetails.roomStartDateTime)
    .sort(getCompareAsc);

  const minStartDate = sortedStartDays[0] || selectedDate;
  const maxStartDate = sortedStartDays[sortedStartDays.length - ONE] || selectedDate;

  let formattedFullSelectedDate = getFullDateInLocalFormat(selectedDate, getCurrentTimezone());
  const formattedMinDate = getFullDateInLocalFormat(minStartDate, timeZone);
  const formattedMaxDate = getFullDateInLocalFormat(maxStartDate, timeZone);

  const localTimeZoneOffset = getTimezoneOffset(getCurrentTimezone());
  const eventTimeZoneOffset = getTimezoneOffset(timeZone);

  if (localTimeZoneOffset > eventTimeZoneOffset) {
    formattedFullSelectedDate = formattedMaxDate;
  }
  if (localTimeZoneOffset < eventTimeZoneOffset) {
    formattedFullSelectedDate = formattedMinDate;
  }


  return (
    <div
      style={{ height: 'max-content', boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.3)' }}
      className={`connection-details-wrapper shadow-md bg-white ${(isPresenterPage ? meetings[selectedDate]?.length : connectionDetails?.length) > limit && 'pb-8'}`}
    >
      {isPresenterPage
        ? (
          <PresenterConnections
            timeZone={timeZone}
            meetings={meetings}
            selectedDate={selectedDate}
            setMeetingsDate={setMeetingsDate}
            isPresenterPage={isPresenterPage}
            organizationName={organizationName}
            connectionDetails={connectionDetails}
            formattedFullSelectedDate={formattedFullSelectedDate}
          />
        )
        : null
      }
      {!isPresenterPage
        ? (
          <ParticipantConnections
            timeZone={timeZone}
            errorMessage={!!errorMessage}
            isPresenterPage={isPresenterPage}
            organizationName={organizationName}
            containsUpcomingOnly={containsUpcomingOnly}
            connectionDetails={connectionDetails}
            formattedFullSelectedDate={formattedFullSelectedDate}
          />
        )
        : null
      }
      {!connectionDetails && errorMessage && <div className='font-size-14px error-message p-0 bg-cool-gray w-100 p-8'>
        <div className='whitespace-pre-wrap leading-loose'>{ errorMessage }</div>
      </div>}

    </div>
  );
};

const DetailsWrapper: React.FunctionComponent<IProps> = (props: IProps) => {
  const { meetingDetails, type, errorMessage, timeZone } = props;
  const customStyle = { '--primary-color': meetingDetails.primaryColor } as React.CSSProperties;
  const eventInfo = {
    startTime: meetingDetails.eventStartDateTime,
    endTime: meetingDetails.eventEndDateTime,
    name: meetingDetails.eventName,
    timeZone: timeZone,
    description: meetingDetails?.publicDescription,
    eventTitleForPassport: meetingDetails?.eventTitleForPassport,
    timeZoneDisplayType: meetingDetails.timeZoneDisplayType,
    customTimeZoneDisplay:  meetingDetails?.customTimeZoneDisplay || '',
  };
  const ref = useRef(null);
  if (AppConfigurator.isMeetLinksProfile)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useChangeBackgroundSize(ref, 'event-details-info');
  return (
    <section style={customStyle}>
      <div className='content-wrapper w-100 bg-white md:flex align-middle pt-8' style={{ height: '45vh' }}>
        <div className='md:w-5/6 m-auto flex justify-between' style={{ maxWidth: '1536px' , margin: '0 auto' }}>
          <EventDetails details={eventInfo} logoUrl={meetingDetails.logoUrl} />
          <ConnectionDetailsWrapper
            type={type}
            timeZone={timeZone}
            errorMessage={errorMessage}
            meetingDetails={meetingDetails}
          />
        </div>
      </div>
      <img className='branding-bg-img' ref={ref} src={meetingDetails.backgroundUrl || BACKGROUND_IMG_URL} alt='' />
    </section>
  );
};

export default DetailsWrapper;
