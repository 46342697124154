import React, { useState } from 'react';
import { JoinMethod } from '../../../lib/join-method/join-method';
import { Button } from '../Button';
import DialInInfo from '../../../app/shared/DialInInfo/DialInInfo';

import './ShowDialInInfoButton.scss';

export type ShowDialInInfoButtonProps = {
  joinMethod: JoinMethod;
}

const ShowDialInInfoButton: React.FunctionComponent<ShowDialInInfoButtonProps> = (props: ShowDialInInfoButtonProps): JSX.Element => {
  const {
    joinMethod
  } = props;

  const [showDialInInfo, setShowDialInInfo] = useState<boolean>(true);
  const toggleDialInInfo = () => setShowDialInInfo(showDialInInfo => !showDialInInfo);
  const isAudioBridgeType = joinMethod.isAudioBridgeType() || joinMethod.isUniqueDialInType();
  const displayShowDialInInfoButton = `connection-item__dial-in ${showDialInInfo ? 'block' : 'hidden'}`;
  const dialInfoButtonTitle = `${showDialInInfo ? 'Hide' : 'Show'} Dial-In Info`;

  return (
    <div className='connection-item'>
      <div
        className={displayShowDialInInfoButton}
      >
        <DialInInfo joinMethod={joinMethod}/>
      </div>
      {isAudioBridgeType && (
        <Button
          className='connection-item__show-dial-button'
          name='toggle-dial'
          onClick={toggleDialInInfo}
        >
          {dialInfoButtonTitle}
        </Button>
      )}
    </div>
  );
};

export default ShowDialInInfoButton;
