export enum JOIN_STATUS {
  NOT_JOINED = 'NOT_JOINED',
  JOINED_AS_REGISTRANT = 'JOINED_AS_REGISTRANT',
  JOINED_AS_REGULAR = 'JOINED_AS_REGULAR'
}

export enum TIME_RANGES {
  MINUTES_IN_HOUR = 60,
  SECONDS_IN_MINUTE = 60,
  MILLISECONDS_IN_SECOND = 1000,
  MILLISECONDS_IN_MINUTE = 60000,
  MILLISECONDS_IN_HOUR = 3600000,
}

export enum TIME_FORMATS {
  Clock_Format_12hr = "h:mm aaaaa'm'",
  Clock_Format_24hr = "HH:mm",
  Event_Start_Date_Long_US = "EEEE, MMMM d, yyyy",
  Event_End_Date_Long_US = "EEEE, MMMM d, yyyy",
  Event_Start_Date_Long_UK = "EEEE, d MMMM yyyy",
  Event_End_Date_Long_UK = "EEEE, d MMMM yyyy",
  Event_Start_Day_D = "d",
  Event_End_Day_D = "d",
  Event_Start_Day_DD = "dd",
  Event_End_Day_DD = "dd",
  Event_Start_Day_DDD = "EEE",
  Event_End_Day_DDD = "EEE",
  Event_Start_Day_DDDD = "EEEE",
  Event_End_Day_DDDD = "EEEE",
  Event_Start_Month_M = "M",
  Event_End_Month_M = "M",
  Event_Start_Month_MM = "MM",
  Event_End_Month_MM = "MM",
  Event_Start_Month_MMM = "MMM",
  Event_End_Month_MMM = "MMM",
  Event_Start_Month_MMMM = "MMMM",
  Event_End_Month_MMMM = "MMMM",
  Event_Start_Year_YY = "yy",
  Event_End_Year_YY = "yy",
  Event_Start_Year_YYYY = "yyyy",
  Event_End_Year_YYYY = "yyyy",
  default = "EEEE, MMMM d, yyyy"
}

export enum TEMPLATE_VARIABLES {
  Event_Name = 'Event_Name',
  Event_Starttime12hr = 'Event_Starttime12hr',
  Event_Endtime12hr = 'Event_Endtime12hr',
  Event_Starttime24hr = 'Event_Starttime24hr',
  Event_Endtime24hr = 'Event_Endtime24hr',
  Event_Start_Date_Long_US = 'Event_Start_Date_Long_US',
  Event_Start_Date_Long_UK = 'Event_Start_Date_Long_UK',
  Event_Start_Day_D = 'Event_Start_Day_D',
  Event_Start_Day_DD = 'Event_Start_Day_DD',
  Event_Start_Day_DDD = 'Event_Start_Day_DDD',
  Event_Start_Day_DDDD = 'Event_Start_Day_DDDD',
  Event_Start_Month_M = 'Event_Start_Month_M',
  Event_Start_Month_MM = 'Event_Start_Month_MM',
  Event_Start_Month_MMM = 'Event_Start_Month_MMM',
  Event_Start_Month_MMMM = 'Event_Start_Month_MMMM',
  Event_Start_Year_YY = 'Event_Start_Year_YY',
  Event_Start_Year_YYYY = 'Event_Start_Year_YYYY',
  Event_End_Date_Long_US = 'Event_End_Date_Long_US',
  Event_End_Date_Long_UK = 'Event_End_Date_Long_UK',
  Event_End_Day_D = 'Event_End_Day_D',
  Event_End_Day_DD = 'Event_End_Day_DD',
  Event_End_Day_DDD = 'Event_End_Day_DDD',
  Event_End_Day_DDDD = 'Event_End_Day_DDDD',
  Event_End_Month_M = 'Event_End_Month_M',
  Event_End_Month_MM = 'Event_End_Month_MM',
  Event_End_Month_MMM = 'Event_End_Month_MMM',
  Event_End_Month_MMMM = 'Event_End_Month_MMMM',
  Event_End_Year_YY = 'Event_End_Year_YY',
  Event_End_Year_YYYY = 'Event_End_Year_YYYY'
}

export const ARRAY_INCREMENT_SIZE = 1;
export const COMPARE_INDEX = 1;
export const MAX_TIMEOUT = 2147483647;

export const OE_TEXT_LABEL = 'OpenExchange';
export const OE_LOGO_IMG_PATH = '/img/OE_Logo.svg';
export const OE_LOGO_ALT_TEXT = `${OE_TEXT_LABEL} Logo`;
export const POWERED_BY_ALT_TEXT = 'Powered by OpenExchange';
export const PASSCODE_EXPIRATION_DAYS = 7;

export const
  PASSCODE_KEY = 'passcode',
  BYPASS_TOKEN_KEY = 'token',
  ATTENDEE_TOKEN_KEY = 'attendeeToken',
  MEET_PATH_KEY = 'meetingPath',
  MEET_ID_KEY = 'meetingId',
  EVENT_ID_KEY = 'eventId',
  ML_ACCESS_TOKEN = 'ml_access_token',
  IDP_IDENTIFIER = 'idp_identifier',
  TYPE = 'type',
  TOKEN = 'token',
  SINGLE_MEETING_PATH = 'singleMeetingPath',
  FORM_VIEW = 'form_view',
  FILTER_REPLAYS = 'filterReplays'
;

/* DEFAULTS */
export const
  BACKGROUND_IMG_URL = '/img/OE_Background.jpg',
  DEFAULT_BACKGROUND_IMG_ULT_TEXT = 'Background image',
  PRIMARY_COLOR = '#00787A',

  ERR_MSG = 'Incorrect email address or access code.',
  REQUEST_ACCESS_CODE = 'REQUEST_ACCESS_CODE',
  REGISTRATION = 'REGISTRATION',
  RADIUS_SPINNER = 40,
  RADIUS_STROKE = 2,
  COLOR_SPINNER = '#000000',
  DEVIDER_BY_TWO = 2,
  HALF_A_MINUTE_IN_MILLISECONDS = TIME_RANGES.MILLISECONDS_IN_MINUTE / DEVIDER_BY_TWO,
  INPUT_DEBOUNCE_TIME = 350
;

/* KNOVIO */
export enum KvEmbedType { SHOWCASE = 'showcase', PRESENTATION = 'presentation' }
export const
  SECURE_PROTO = 'https',
  SHOWCASE = 'showcase',
  EMBED = 'embed',
  THUMBNAIL = 'thumbnail',
  PRESENTATION = 'presentation',
  EMBEDDED_CONTENT = 'KnowledgeVisionEmbeddedContent'
;

// StringValidators
export const REQUIRED = (v: string): boolean => Boolean(v);
// CheckBoxValidators
export const REQUIRED_CHECKBOX = (v: string): boolean => v === 'TRUE';
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const MIN6CHAR = (v = '', len = 6): boolean => v.length >= len;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const SHORT_PLAIN_TEXT_LENGTH = (v = '', len = 255): boolean => v.length <= len;
export const MEDIUM_PLAIN_TEXT_LENGTH = (v = '', len = MEDIUM_PLAIN_TEXT_MAX_LENGTH): boolean => v.length <= len;
// eslint-disable-next-line
export const EMAIL_REGEXP = (v = ''): boolean => v ? /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) : true;
export const SPACE_TRIMMER = (v = ''): string => String.prototype.trim.apply(v);
export const FIRST_SPACE_TRIMMER = (v = ''): string => String.prototype.trimStart.apply(v);
export const SESSION_MEETING_PATH_REGEX = /session\/\d+\/meeting\/\d+/;
export const AGENDA_MEETING_PATH_REGEX = /agenda\/\d+\/meeting\/\d+/;
export const STRING_WITHOUT_HTML_REGEX = /<[^>]*>?/g;
export const STRING_WITH_HYPHEN_REGEX = /(.{3})(?=.)/g;
interface IAutoCompleteVelues {
  [key: string]: string
}

export const AUTO_COMPLETE_VALUES: IAutoCompleteVelues = {
  firstName: 'given-name',
  lastName: 'family-name',
  organizationName: 'organization',
  salutation: 'on',
  phone: 'tel-local',
  title: 'on',
  email: 'email',
};

export const ATTENDEE_COLLAPSED_BIO_MAX_HEIGHT = 60;
export const REPLAY_TILE_LEFTOVER_CONTENT_HEIGHT = 130;
export const FEATURED_TILE_LEFTOVER_CONTENT_HEIGHT = 100;
export const NEXT_TILE_LEFTOVER_CONTENT_HEIGHT = 140;
export const FEATURE_CONTENT_HIGHT_BEFORE_EXPAND = 154;
export const REPLAYS_CONTENT_HIGHT_BEFORE_EXPAND = 220;
export const MEDIUM_PLAIN_TEXT_MAX_LENGTH = 2048;

export enum CheckBoxValues {
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}

export const CheckBoxMapper: { [key: string]: boolean } = {
  [`${CheckBoxValues.TRUE}`]: true,
  [`${CheckBoxValues.FALSE}`]: false
};

export const COOKIE_NOTIFICATION_KEY = 'cookieNotification';
export const COOKIE_NOTIFICATION_EXPIRATION_DAYS = 180;

export enum ItemCounts {
  ZERO,
  ONE,
  TWO,
  THREE,
  FOUR,
}

/* SSO Error Messages */
export enum SSO_ERROR_MSG {
  AUTH_FAILURE = 'You are not authorised to access this event by SSO',
  USER_AUTH_FAILURE = 'You are not authorised to access this event. Please contact the Event Coordinator.'
}

export const REG_DATA = 'REG_DATA';

export const ERR_MODAL_COMMON_STYLES = {
  overlay: {
    zIndex: 9,
    background: 'rgba(255,255,255, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '40px',
    width: '500px',
    boxShadow: '6px 6px 20px 0 rgba(0, 0, 0, 0.3)',
  }
};

export enum ONE_TAP_FORMULA {
  PHONE_NUMBER = '{telephone_number}',
  CONFERENCE_ID = '{conference_id}',
  PARTICIPANT_ID = '{participant_id}',
  PASSCODE = '{passcode}',
}
